.vehicle-list {
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
}

.vehicle-list-item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.vehicle-list-item-content-container {
    display: flex;
    justify-content: flex-start;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.vehicle-list-item-extra-content {
    margin-left: 14px;
}

.address {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    font-size: 12px;
}

.address > * {
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
}
