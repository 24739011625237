.ButtonLink {
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}
  
.ButtonLink:hover, .ButtonLink:focus {
    text-decoration: underline;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}