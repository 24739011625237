.inputContainer {
    margin-bottom: 5px;
}
.inputButtonContainer {
    display: flex;
    flex-direction: row;
}

.inputLabel {
    display: flex;
    
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    margin-top: 6px;
    margin-right: 5px;
}

.inputField {
    /* flex: 1; */

    min-width: 195px;
    align-items: left;
}