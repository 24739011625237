body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-row {
  border-bottom: solid 1px lightgray;
}

.ButtonLink {
  color: blue;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ButtonLink:hover, .ButtonLink:focus {
  text-decoration: underline;
}

.POI {
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background-color: #5C1A8E;
  font-size: 10px;
}

.POI_Description {
  font-size: 12px;
  font-weight: bold;
  color: #5C1A8E;
}